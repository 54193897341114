import { gql } from "@apollo/client";

export const GET_NEWS = gql`
  query GetNews {
    news(order_by: { created_at: desc }) {
      id
      name
      description
      image_name
      created_at
      updated_at
    }
  }
`;

export const GET_NEWS_BY_ID = gql`
  query GetNewsById($id: Int!) {
    news_by_pk(id: $id) {
      id
      name
      description
      image_name
      created_at
    }
  }
`;