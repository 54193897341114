import { gql } from '@apollo/client';

export const GET_QUESTIONS = gql`
  query GetQuestions {
    question(order_by: {created_at: asc}) {
      id
      created_at
      updated_at
      question
      answer
    }
  }
`;
