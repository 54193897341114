import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MainLayout from '../layout/MainLayout'
import HomeView from '../view/HomeView'
import Shop from '../view/Shop'
import AboutUs from '../view/AboutUs'
import ContactUs from '../view/ContactUs'
import FAQs from '../view/FAQs'
import NewsEvent from '../view/NewsEvent'


function MainRouter() {
  return (
    <Routes>
        <Route element={<MainLayout/>}>
            <Route index path="/" element={<HomeView/>} />
            <Route path="/shop" element={<Shop/>} />
            <Route path="/aboutus" element={<AboutUs/>} />
            <Route path="/contactus" element={<ContactUs/>} />
            <Route path="/faqs" element={<FAQs/>} />
            <Route path="/news/:id" element={<NewsEvent />} />
        </Route>
    </Routes>
  )
}
 
export default MainRouter