import { gql } from '@apollo/client';

export const SEND_CONTACT_FORM = gql`
  mutation sendContactForm($email: String!, $message: String!, $name: String!) {
    sendContactForm(email: $email, message: $message, name: $name) {
      error
      message
    }
  }
`;
