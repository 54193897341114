import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/squarelogo.png";
import { Icon } from "@iconify/react";

function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="fixed w-full z-10 bg-white">
      <div className="lg:w-10/12 md:w-11/12 w-full md:px-0 px-3 lg:h-20 md:h-16 h-12 mx-auto flex justify-between items-center">
        <div>
          <img src={logo} alt="" className=" lg:w-40 md:w-32 w-24" />
        </div>
        <div className=" md:flex hidden">
          <ul className="flex justify-around lg:space-x-16 md:space-x-10 font-bold">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? " text-secondaryColor underline underline-offset-8"
                    : " opacity-50 hover:text-secondaryColor hover:opacity-100 hover:underline hover:underline-offset-8"
                }
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/shop"
                className={({ isActive }) =>
                  isActive
                    ? " text-secondaryColor underline underline-offset-8"
                    : " opacity-50 hover:text-secondaryColor hover:opacity-100 hover:underline hover:underline-offset-8"
                }
              >
                Shop
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/aboutus"
                className={({ isActive }) =>
                  isActive
                    ? " text-secondaryColor underline underline-offset-8"
                    : " opacity-50 hover:text-secondaryColor hover:opacity-100 hover:underline hover:underline-offset-8"
                }
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contactus"
                className={({ isActive }) =>
                  isActive
                    ? " text-secondaryColor underline underline-offset-8"
                    : " opacity-50 hover:text-secondaryColor hover:opacity-100 hover:underline hover:underline-offset-8"
                }
              >
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/faqs"
                className={({ isActive }) =>
                  isActive
                    ? " text-secondaryColor underline underline-offset-8"
                    : " opacity-50 hover:text-secondaryColor hover:opacity-100 hover:underline hover:underline-offset-8"
                }
              >
                FAQS
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Mobile Menu Toggle Button */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu}>
            <Icon icon="mdi:menu" className="text-3xl" />
          </button>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-white w-full absolute top-full left-0 z-20 shadow-lg">
          <ul className="flex flex-col items-center py-4 font-bold">
            <li>
              <NavLink
                to="/"
                onClick={() => setIsMenuOpen(false)}
                className={({ isActive }) =>
                  isActive
                    ? "block w-full text-secondaryColor py-2 underline underline-offset-8 text-center"
                    : "block w-full opacity-50 hover:text-secondaryColor py-2 hover:opacity-100 hover:underline hover:underline-offset-8 text-center"
                }
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/shop"
                onClick={() => setIsMenuOpen(false)}
                className={({ isActive }) =>
                  isActive
                    ? "block w-full text-secondaryColor py-2 underline underline-offset-8 text-center"
                    : "block w-full opacity-50 hover:text-secondaryColor py-2 hover:opacity-100 hover:underline hover:underline-offset-8 text-center"
                }
              >
                Shop
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/aboutus"
                onClick={() => setIsMenuOpen(false)}
                className={({ isActive }) =>
                  isActive
                    ? "block w-full text-secondaryColor py-2 underline underline-offset-8 text-center"
                    : "block w-full opacity-50 hover:text-secondaryColor py-2 hover:opacity-100 hover:underline hover:underline-offset-8 text-center"
                }
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contactus"
                onClick={() => setIsMenuOpen(false)}
                className={({ isActive }) =>
                  isActive
                    ? "block w-full text-secondaryColor py-2 underline underline-offset-8 text-center"
                    : "block w-full opacity-50 hover:text-secondaryColor py-2 hover:opacity-100 hover:underline hover:underline-offset-8 text-center"
                }
              >
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/faqs"
                onClick={() => setIsMenuOpen(false)}
                className={({ isActive }) =>
                  isActive
                    ? "block w-full text-secondaryColor py-2 underline underline-offset-8 text-center"
                    : "block w-full opacity-50 hover:text-secondaryColor py-2 hover:opacity-100 hover:underline hover:underline-offset-8 text-center"
                }
              >
                FAQs
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Nav;
