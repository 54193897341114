import React from 'react'
import Home from '../components/Home'


function HomeView() {
  return (
    <>
        <Home/>
      
    </>
  )
}

export default HomeView