import React, { useState } from "react";
import { useMutation } from '@apollo/client';
import { SEND_CONTACT_FORM } from '../graphql/mutations/sendContactFormMutation';  // Adjust the path as needed

import EmailIcon from "../assets/Email.png";
import PhoneIcon from "../assets/phone.png";
import ViberIcon from "../assets/viber logo.png";
import MessengerIcon from "../assets/messenger logo.png";
import LocationIcon from "../assets/Location.png";
import FacebookIcon from "../assets/facebook-rect logo.png";

function ContactUs() {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [sendContactForm, { data, loading, error }] = useMutation(SEND_CONTACT_FORM);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendContactForm({
        variables: {
          name: formData.name,
          email: formData.email,
          message: formData.message,
        },
      });
      
      if (response?.data?.sendContactForm?.message) {
        setFormData({ name: "", email: "", message: "" });
      }
    } catch (err) {
      console.error('Error sending contact form:', err);
    }
  };

  return (
    <div className="w-full text-white md:pt-48 py-12">
      <div className="lg:w-10/12 w-11/12 md:flex md:flex-row flex-col md:justify-between md:space-x-5 mx-auto ">
        {/* Left Section */}
        <div className="md:w-6/12">
          <p className="lg:text-3xl text-2xl font-bold mt-12">How Can We Help?</p>

          {/* Phone Section */}
          <a href="tel:+959252787662" className="block">
            <div className="flex items-center pt-8">
              <img src={PhoneIcon} alt="Phone" />
              <p className="lg:text-lg text-base ml-6">+959252787662</p>
            </div>
          </a>

          {/* Email Section */}
          <a href="mailto:martcom.thiha@gmail.com" className="block">
            <div className="flex items-center pt-8">
              <img src={EmailIcon} alt="Email" />
              <p className="lg:text-lg text-base ml-6">martcom.thiha@gmail.com</p>
            </div>
          </a>

          {/* Location Section */}
          <div className="flex items-center pt-8">
            <img src={LocationIcon} alt="Location" className="w-8 h-8" />
            <p className="lg:text-lg text-base ml-6">No. 46/48, 6th Fl., Bogyoke Aung San Rd.,Kan Shae Qt., Taunggyi</p>
          </div>

          {/* Social Media Icons */}
          <div className="flex space-x-8 mt-14">
            <a href="https://www.facebook.com/citysquareshoppingcenter?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
              <img src={FacebookIcon} alt="Facebook" className="lg:w-10 w-8" />
            </a>
            <a href="https://invite.viber.com/?g2=AQBf5a7QP8dBNFMXjU7QQFCnKjyCMfpiDcnOYOlCMPL0jrgkEq5MgEpSOK9dqXkY" target="_blank" rel="noopener noreferrer">
              <img src={ViberIcon} alt="Viber" className="lg:w-10 w-8" />
            </a>
            <a href="https://www.messenger.com/t/yourprofile" target="_blank" rel="noopener noreferrer">
              <img src={MessengerIcon} alt="Messenger" className="lg:w-10 w-8" />
            </a>
          </div>
        </div>

        {/* Right Section */}
        <div className="md:w-6/12">
          <form onSubmit={handleSubmit}>
            <div className="mt-12 w-full">
              <label className="block lg:text-lg text-base">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full mt-2 py-2 px-3 rounded-md bg-transparent border-gray-400 border text-white"
                required
              />
            </div>

            <div className="mt-8">
              <label className="block lg:text-lg text-base">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full mt-2 py-2 px-3 rounded-sm bg-transparent border-gray-400 border text-white"
                required
              />
            </div>

            <div className="mt-8">
              <label className="block lg:text-lg text-base">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                className="w-full mt-2 h-40 py-2 px-3 rounded-sm bg-transparent border-gray-400 border text-white"
                required
              />
            </div>

            <div className="mt-8">
              <button type="submit" className="bg-primaryBackground text-secondaryColor font-bold lg:text-lg text-base w-full py-3 rounded-sm" disabled={loading}>
                {loading ? 'Sending...' : 'Send Message'}
              </button>
              {error && <p className="text-red-500 mt-2">Error: {error.message}</p>}
              {data && <p className="text-green-500 mt-2">Message sent successfully!</p>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
