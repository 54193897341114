import React, { useEffect, useRef, useState } from "react";
import malllocation from "../assets/shoplocation.png";
import { GET_SHOPS, GET_CATEGORIES } from "../graphql/queries/shopQueries";
import { useQuery } from "@apollo/client";

function Shop() {
  const mallDirectoryImgs = [malllocation,malllocation,malllocation,malllocation];
  const floorNames = ["Ground Floor", "1st Floor", "2nd Floor", "3rd Floor"];
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const {
    data: shopData,
    loading: shopsLoading,
    error: shopsError,
  } = useQuery(GET_SHOPS);
  const {
    data: categoryData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useQuery(GET_CATEGORIES);
  const [selectedCategory, setSelectedCategory] = useState("Fashion");

  // References to each floor for scrolling
  const floorRefs = useRef({});
  // State to store highlighted shop ID
  const [highlightedShop, setHighlightedShop] = useState(null);

  // Effect to reset highlighted shop after 10 seconds
  useEffect(() => {
    if (highlightedShop !== null) {
      const timer = setTimeout(() => {
        setHighlightedShop(null); 
      }, 10000);

      // Clear the timeout if the component unmounts or highlightedShop changes
      return () => clearTimeout(timer);
    }
  }, [highlightedShop]);

  // Handle loading and error states for both queries
  if (shopsLoading || categoriesLoading) return <p>Loading...</p>;
  if (shopsError || categoriesError) return <p>Error loading data!</p>;

  // Extract categories from the fetched data
  const categories = categoryData?.category || [];

  // Extract shop images from the fetched data
  const shops = shopData?.shop || [];

  // Scroll to the floor and highlight the shop
  const handleShopClick = (shop) => {
    setHighlightedShop(shop.id);

    // Scroll to the floor where the shop is located
    if (floorRefs.current[shop.floor]) {
      floorRefs.current[shop.floor].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // Filter Shops
  const filteredShops = selectedCategory
    ? shops.filter(shop => shop.category.category_name === selectedCategory)
    : shops;
    
  // Group shops by floor
  const groupedShops = filteredShops.reduce((acc, shop) => {
    acc[shop.floor] = acc[shop.floor] ? [...acc[shop.floor], shop] : [shop];
    return acc;
  }, {});

  // Handle category selection
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  // Handle dropdown change
  const handleImageChange = (e) => {
    setSelectedImageIndex(e.target.value);
  };

  return (
    <div className="lg:pt-20 md:pt-16 pt-12">
      <div className="py-8">
        <p className="text-center text-secondaryColor lg:text-3xl text-2xl font-extrabold">
          Shops By Category
        </p>
        <div className="md:w-10/12 w-11/12 mx-auto ">
          <div className="w-full overflow-x-auto whitespace-nowrap">
            {categories.map((category, index) => (
              <button
                key={index}
                onClick={() => handleCategoryClick(category.category_name)}
                className={`px-2 mx-2 py-2 my-4 text-secondaryColor border-secondaryColor md:border-[2px] border-[1px] md:text-base text-sm hover:bg-secondaryColor hover:text-white ${
                  selectedCategory === category.category_name ? "bg-secondaryColor text-white" : ""
                }`}
              >
                {category.category_name}
              </button>
            ))}
          </div>
          <div className="md:flex md:flex-wrap md:justify-center md:items-center grid grid-cols-3 justify-items-center items-center mx-auto md:mt-0 mt-6">
            {filteredShops.map((shop) => (
              <div
                key={shop.id}
                className="lg:w-44 md:w-36 w-28 lg:h-44 md:h-36 h-28 border-gray-200 border shadow-sm flex justify-center items-center lg:mr-6 md:mr-7 mb-6 cursor-pointer"
                onClick={() => handleShopClick(shop)}
              >
                <img
                  src={shop.shop_logo}
                  alt={`Shop ${shop.shop_name}`}
                  className="lg:w-40 md:w-32 w-24 lg:h-40 md:h-32 h-24 object-scale-down"
                />
              </div>
            ))}
          </div>
        </div>
        {/* Dropdown for mall directory images */}
        <div className="w-11/12 mt-12 mx-auto flex justify-start">
          <select
            value={selectedImageIndex}
            onChange={handleImageChange}
            className="border border-gray-300 rounded-md p-2"
          >
            {mallDirectoryImgs.map((_, index) => (
              <option key={index} value={index}>
                {floorNames[index]}
              </option>
            ))}
          </select>
        </div>
        {/* Display selected mall directory image */}
        <div className="lg:w-full w-11/12 mt-4 mx-auto flex justify-center">
          <img src={mallDirectoryImgs[selectedImageIndex]} alt={`Mall Directory ${selectedImageIndex + 1}`} />
        </div>

        {/* Mall Directory */}
        <div className="w-9/12 py-12 mx-auto">
          <p className="text-center text-secondaryColor lg:text-3xl text-2xl font-extrabold">
            Mall Directory
          </p>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-8 mt-6">
            {Object.keys(groupedShops).map((floor) => (
              <div
                key={floor}
                ref={(el) => (floorRefs.current[floor] = el)}
                className="w-full"
              >
                <p className="text-secondaryColor md:text-lg font-bold">{`Floor ${floor}`}</p>
                <hr className="border-0 border-t border-dashed border-gray-400 mb-4" />
                {groupedShops[floor].map((shop) => (
                  <div key={shop.id} className="mt-4">
                    <p
                      className={`py-1 px-2 inline text-white mr-4 md:text-base text-sm ${
                        highlightedShop === shop.id
                          ? "bg-red-700"
                          : "bg-secondaryColor"
                      }`}
                    >
                      {shop.id}
                    </p>
                    <span
                      className={`md:text-base text-sm ${
                        highlightedShop === shop.id
                          ? "font-bold text-red-700"
                          : ""
                      }`}
                    >
                      {shop.shop_name}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shop;
