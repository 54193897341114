import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import "../style/slider.css";
import slide1 from "../assets/slide1.png";
import slide4 from "../assets/slide4.png";
import slide5 from "../assets/slide5.png";
import restaurant from "../assets/icons/restaurant.png";
import sloon from "../assets/icons/beauty-sloon.png";
import diamond from "../assets/icons/diamond.png";
import plug from "../assets/icons/plug.png";
import mom from "../assets/icons/mom.png";
import mic from "../assets/icons/mic.png";
import gallery1 from "../assets/gallery1.png";
import gallery2 from "../assets/gallery2.png";
import gallery3 from "../assets/gallery3.png";
import gallery4 from "../assets/gallery4.png";
import gallery5 from "../assets/gallery5.png";
import gallery6 from "../assets/gallery6.png";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay, EffectCoverflow } from "swiper/modules";
import { GET_BANNERS } from "../graphql/queries/bannerQueries";
import { GET_NEWS } from "../graphql/queries/newsQueries";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { motion } from "framer-motion";

function Home() {
  const { data: bannerData, loading, error } = useQuery(GET_BANNERS);
  const { data: newsData } = useQuery(GET_NEWS);

  // Create a copy of the news array, sort it, and limit to 2 items
  const sortedNews = [...(newsData?.news || [])]
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .slice(0, 2);

  const images = bannerData?.banner?.map((banner) => banner.image_name) || [];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically slide to the next image every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [images.length]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Framer Motion variants for different animations
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const slideInLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  };

  const slideInRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };

  const slideUp = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      {/* Image Slider Section */}
      <motion.div
        className="swiper-container lg:pt-20 md:pt-16 pt-12 relative overflow-hidden"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 1 }}
      >
        <div
          className="swiper-wrapper"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className="swiper-slide -z-10"
              style={{ height: "800px", width: "100%", objectFit: "cover" }}
            />
          ))}
        </div>
        <div className="swiper-indicators z-10">
          {images.map((_, index) => (
            <span
              key={index}
              className={`indicator ${index === currentIndex ? "active" : ""}`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      </motion.div>
      {/* Welcome Section */}
      <motion.div
        className="lg:w-9/12 w-11/12 mx-auto pt-12 md:pb-20 pb-12 md:flex md:justify-between md:items-center md:space-x-24"
        initial="hidden"
        animate="visible"
        variants={slideInLeft}
        transition={{ duration: 1 }}
      >
        <div className=" md:w-5/12 relative ml-3 ">
          <img
            src={slide4}
            alt=""
            className=" lg:w-[30rem] md:w-[26rem] w-[19rem] lg:h-72 md:h-56 h-52"
          ></img>
          <img
            src={slide5}
            alt=""
            className=" lg:w-[30rem] md:w-[26rem] w-[19rem] lg:h-72 md:h-56 h-52 absolute md:top-8 md:left-8 top-6 left-6"
          ></img>
          <img
            src={slide1}
            alt=""
            className=" lg:w-[30rem] md:w-[26rem] w-[19rem] lg:h-72 md:h-56 h-52  absolute md:top-16 md:left-16 top-12 left-12"
          ></img>
        </div>
        <div className="md:w-7/12 md:mt-0 mt-20">
          <p className=" font-extrabold text-secondaryColor lg:text-3xl md:text-2xl md:leading-[3.2rem] text-2xl">
            Welcome to <br className="md:flex hidden" /> City Square Shopping
            Center
          </p>
          <p className="text-secondaryColor leading-[1.6rem] mt-6 lg:text-base text-sm">
            We’re thrilled to have you here. Discover a diverse array of shops,
            dining options, and entertainment venues designed to meet all your
            needs and preferences. Enjoy a convenient and delightful shopping
            experience with us!
          </p>
        </div>
      </motion.div>
      {/* About Us Section */}
      <motion.div
        className="w-full bg-primaryBackground py-12 md:mt-8"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
        transition={{ duration: 1 }}
      >
        <motion.div
          className="lg:w-9/12 md:w-11/12 mx-auto"
          variants={slideInLeft}
        >
          <div className=" w-11/12 mx-auto md:flex md:justify-between">
            <p className="md:w-4/12 text-secondaryColor font-extrabold lg:text-3xl text-2xl">
              About Us
            </p>
            <div className="md:w-7/12 md:mt-0 mt-4">
              <p className="text-secondaryColor lg:text-base text-sm">
                City Square Shopping Center Taunggyi is a bustling center of
                business activity right in the heart of the city. This shopping
                Centre is a popular destination for both locals....
              </p>
              <Link
                to={"/aboutus"}
                className="flex items-center space-x-2 mt-2"
              >
                <span className="lg:text-base text-sm text-secondaryColor">
                  Read More
                </span>
                <Icon
                  icon="pajamas:long-arrow"
                  className="lg:text-xl text-base text-secondaryColor"
                />
              </Link>
            </div>
          </div>
          <div className="md:flex hidden md:justify-between mt-6">
            <img
              src={slide1}
              alt=""
              className="lg:w-[26rem] md:w-[17.5rem] lg:h-64 md:52"
            />
            <img
              src={slide5}
              alt=""
              className="lg:w-[26rem] md:w-[17.5rem] lg:h-64 md:52"
            />
            <img
              src={slide4}
              alt=""
              className="lg:w-[26rem] md:w-[17.5rem] lg:h-64 md:52"
            />
          </div>

          {/* Mobile Image Slider */}
          <div className="md:hidden w-screen mt-6 overflow-hidden">
            <Swiper
              spaceBetween={40} // Adjust space between slides to ensure upcoming slide is visible
              slidesPerView={1.4} // Slightly reduce this to make the upcoming slide more visible
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              loop={true}
              centeredSlides={true} // Ensures the active slide is centered
              effect={"coverflow"}
              coverflowEffect={{
                rotate: 0, // 0 rotation keeps slides flat
                stretch: 0,
                depth: 50, // Adjust depth to manage how much of next slide is visible
                modifier: 1,
                slideShadows: false, // Turn off shadows for better visibility
              }}
              initialSlide={0} // Start with the first slide
              modules={[Autoplay, EffectCoverflow]}
            >
              <SwiperSlide>
                <img
                  src={slide1}
                  alt="Slide 1"
                  className="w-full h-52 object-cover"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={slide5}
                  alt="Slide 2"
                  className="w-full h-52 object-cover"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={slide4}
                  alt="Slide 3"
                  className="w-full h-52 object-cover"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={slide1}
                  alt="Slide 1"
                  className="w-full h-52 object-cover"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={slide5}
                  alt="Slide 2"
                  className="w-full h-52 object-cover"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={slide4}
                  alt="Slide 3"
                  className="w-full h-52 object-cover"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </motion.div>
      </motion.div>
      {/* Services Section */}
      <motion.div
        className="w-full py-12"
        initial="hidden"
        whileInView="visible"
        variants={slideUp}
        transition={{ duration: 1 }}
      >
        <div className="lg:w-8/12 md:w-10/12 w-11/12 mx-auto">
          <p className="lg:text-3xl text-2xl text-secondaryColor font-extrabold text-center">
            Services
          </p>
          <div className=" grid md:grid-cols-3 grid-cols-2 md:gap-12 gap-6 mt-8">
            <motion.div
              className="flex flex-col justify-center items-center"
              whileHover={{ scale: 1.05 }}
              variants={slideInLeft}
              transition={{ duration: 0.8 }}
            >
              <img src={restaurant} alt="" className="lg:w-20 w-16" />
              <span className="mt-3 text-secondaryColor lg:text-base text-sm text-center">
                Restaurant
              </span>
            </motion.div>
            <motion.div
              className="flex flex-col justify-center items-center"
              whileHover={{ scale: 1.05 }}
              variants={slideInRight}
              transition={{ duration: 0.8 }}
            >
              <img src={sloon} alt="" className="lg:w-20 w-16" />
              <span className="mt-3 text-secondaryColor lg:text-base text-sm text-center">
                Beauty & health wellness
              </span>
            </motion.div>
            <motion.div
              className="flex flex-col justify-center items-center"
              whileHover={{ scale: 1.05 }}
              variants={slideInLeft}
              transition={{ duration: 0.8 }}
            >
              <img src={diamond} alt="" className="lg:w-20 w-16" />
              <span className="mt-3 text-secondaryColor lg:text-base text-sm text-center">
                Jewelry
              </span>
            </motion.div>
            <motion.div
              className="flex flex-col justify-center items-center"
              whileHover={{ scale: 1.05 }}
              variants={slideInRight} // Slide in from right
              transition={{ duration: 0.8 }}
            >
              <img src={plug} alt="" className="lg:w-20 w-16" />
              <span className="mt-3 text-secondaryColor lg:text-base text-sm text-center">
                Electronic Products
              </span>
            </motion.div>
            <motion.div
              className="flex flex-col justify-center items-center"
              whileHover={{ scale: 1.05 }}
              variants={slideInLeft} // Slide in from left
              transition={{ duration: 0.8 }}
            >
              <img src={mom} alt="" className="lg:w-20 w-16" />
              <span className="mt-3 text-secondaryColor lg:text-base text-sm text-center">
                Fashion & clothing
              </span>
            </motion.div>
            <motion.div
              className="flex flex-col justify-center items-center"
              whileHover={{ scale: 1.05 }}
              variants={slideInRight} // Slide in from right
              transition={{ duration: 0.8 }}
            >
              <img src={mic} alt="" className="lg:w-20 w-16" />
              <span className="mt-3 text-secondaryColor lg:text-base text-sm text-center">
                Entertainment
              </span>
            </motion.div>
          </div>
        </div>
      </motion.div>
      {/* Gallery Section */}
      <motion.div
        className="w-full md:py-12 pb-12"
        initial="hidden"
        whileInView="visible"
        variants={fadeIn}
        transition={{ duration: 1 }}
      >
        <div className="lg:w-9/12 w-11/12 mx-auto">
          <p className="lg:text-3xl text-2xl text-secondaryColor font-extrabold text-center">
            Gallery
          </p>
          <div className="grid md:grid-cols-3 grid-cols-2 gap-4 mt-8">
            <img
              src={gallery1}
              alt=""
              className="lg:w-[28rem] md:w-[26rem] lg:h-64 md:h-52"
            />
            <img
              src={gallery2}
              alt=""
              className="lg:w-[28rem] md:w-[26rem] lg:h-64 md:h-52"
            />
            <img
              src={gallery3}
              alt=""
              className="lg:w-[28rem] md:w-[26rem] lg:h-64 md:h-52"
            />
            <img
              src={gallery4}
              alt=""
              className="lg:w-[28rem] md:w-[26rem] lg:h-64 md:h-52"
            />
            <img
              src={gallery5}
              alt=""
              className="lg:w-[28rem] md:w-[26rem] lg:h-64 md:h-52"
            />
            <img
              src={gallery6}
              alt=""
              className="lg:w-[28rem] md:w-[26rem] lg:h-64 md:h-52"
            />
          </div>
        </div>
      </motion.div>
      {/* News & Events */}
      <motion.div
        className="w-full py-8 bg-extraColor mb-12"
        initial="hidden"
        whileInView="visible"
        variants={fadeIn} // Slide in from left
        transition={{ duration: 1 }}
      >
        <div className="lg:w-9/12 w-11/12 mx-auto">
          <p className="lg:text-3xl text-2xl text-secondaryColor font-extrabold text-center">
            News & Events
          </p>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6 mt-8">
            {sortedNews.map((newsItem) => (
              <motion.div
                key={newsItem.id}
                className="relative"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                variants={slideInRight}
              >
                <img src={newsItem.image_name} alt="newsimg" />
                <div className="text-secondaryColor flex flex-col space-y-3 mt-4">
                  <p className="flex items-center space-x-4">
                    <Icon
                      icon="formkit:date"
                      className="lg:text-2xl md:text-xl"
                    />
                    <span className="lg:text-base text-sm">
                      {moment(newsItem.created_at).format("MMM Do YYYY")}
                    </span>
                  </p>
                  <p className="font-extrabold">{newsItem.name}</p>
                  <p className="lg:text-base text-sm">
                    {newsItem.description.slice(0, 80)}
                    {newsItem.description.length > 80 && "..."}
                  </p>

                  <Link
                    to={`/news/${newsItem.id}`}
                    className="flex items-center space-x-2 mt-2"
                  >
                    <span className="lg:text-base text-sm font-bold">
                      Read More
                    </span>
                    <Icon
                      icon="pajamas:long-arrow"
                      className="lg:text-xl text-base"
                    />
                  </Link>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Home;
