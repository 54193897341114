import React from 'react'
import thihalogo from "../assets/thihalogo.png";

function Footer() {
  return (
    <div className='w-full bg-secondaryColor flex justify-center items-center space-x-8 '>
        <img src={thihalogo} alt='' className='lg:w-14 w-12 ' />
        <p className=' text-[#fff] lg:text-sm text-xs'>copyright  2024. THIHA Development</p>
    </div>
  )
}

export default Footer