import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { GET_QUESTIONS } from "../graphql/queries/faqsQueries";
import { useQuery } from "@apollo/client";
import { motion } from "framer-motion"; // Import Framer Motion

function FAQs() {
  const { data, loading, error } = useQuery(GET_QUESTIONS);
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="lg:pt-20 md:pt-16 pt-12">
      <p className="lg:text-3xl text-2xl font-extrabold text-secondaryColor text-center py-8">
        Frequently Asked Questions
      </p>
      <div className="lg:w-9/12 md:w-10/12 w-11/12 mx-auto">
        {data.question.map((faq, index) => (
          <div key={index} className="mb-4">
            {/* Question section */}
            <div
              className="flex justify-between cursor-pointer py-4"
              onClick={() => toggleFAQ(index)}
            >
              <p className="md:text-lg font-semibold">{faq.question}</p>
              <div>
                {openIndex === index ? (
                  <Icon icon="iconamoon:arrow-up-2" className="md:text-2xl text-xl" />
                ) : (
                  <Icon icon="iconamoon:arrow-down-2" className="md:text-2xl text-xl" />
                )}
              </div>
            </div>

            {/* Answer section with animation */}
            <motion.div
              initial={{ height: 0, opacity: 0 }} // Start hidden
              animate={{
                height: openIndex === index ? "auto" : 0,
                opacity: openIndex === index ? 1 : 0,
              }} // Animate height and opacity
              transition={{ duration: 0.4 }} // Smooth transition
              className="overflow-hidden" // Prevent content overflow during animation
            >
              <div className="pb-4 md:text-base text-sm">
                <p>{faq.answer}</p>
              </div>
            </motion.div>

            <hr />
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQs;
