import React from "react";
import car from "../assets/car.png";
import basli from "../assets/basil_shopping-bag-solid.png";
import ming from "../assets/mingcute_hotel-fill.png";
import aboutusphoto from "../assets/aboutusphoto.png";

function AboutUs() {
  return (
    <div className="lg:pt-20 md:pt-16 pt-12">
      <p className="text-secondaryColor font-extrabold lg:text-3xl text-2xl text-center pt-8">
        About Us
      </p>
      <div className="mt-8 lg:w-10/12 w-11/12 mx-auto">
        <div className="mt-8 w-full lg:h-[600px] h-auto">
          <img
            src={aboutusphoto}
            alt=""
            className="w-full h-full object-cover"
          ></img>
        </div>
        <div className="pt-8 mb-8">
          <span className="lg:text-lg md:text-base text-sm lg:leading-10 md:leading-8 leading-6 text-secondaryColor">
            City Square Shopping Center Taunggyi is a bustling center of
            business activity right in the heart of the city. This shopping
            Centre is a popular destination for both locals and visitors,
            situated amidst the beautiful scenery of Shan State, Myanmar. A wide
            variety of stores and boutiques, including electronics, home goods,
            local handicrafts, fashion apparel, and accessories, are available
            for exploration by guests of City Square Shopping Center. Shoppers
            have access to a wide range of products at various price points,
            accompanied by well-known global brands: GIORDANO, Miniso, ERKE,
            Yves Rocher, D&C, RENE Mattias and regional merchants. Ample
            parking, clean restrooms, and well-kept walkways improve the
            experience for guests as a whole. We put the convenience of our
            customers first, making sure that everyone can shop and explore
            without difficulty. City Square Shopping Center is well-positioned
            to grow and develop alongside Taunggyi, becoming an even more
            essential part of the neighborhood and offering a wider range of
            services. By maintaining a focus on quality, diversity, and client
            satisfaction, we hope to continue being a top choice for locals and
            visitors looking for an unforgettable shopping and entertainment
            experience.
          </span>
        </div>
      </div>
      <div className="lg:w-10/12 w-11/12 mx-auto grid md:grid-cols-3 grid-cols-1 md:gap-10 gap-5 md:mt-20 md:mb-20 mb-10">
        <div className="flex flex-col justify-center items-center">
          <img src={car} alt="" className="md:w-auto w-16 mt-2" />
          <span className="mt-5 font-extrabold lg:text-lg"> Easy Access </span>
          <p className=" lg:text-base text-sm md:mt-4 mt-2 text-center">
            City Square offers easy access and plenty of parking,ensuring a
            stress-free shopping experience.
          </p>
        </div>

        <div className="flex flex-col justify-center items-center">
          <img src={basli} alt="" className="md:w-auto w-16 " />
          <span className="mt-3 font-extrabold lg:text-lg">
            {" "}
            Fashion Choices{" "}
          </span>
          <p className=" lg:text-base text-sm md:mt-4 mt-2 text-center">
            This shopping center caters to both your daily necessities and
            fashion preferences.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img src={ming} alt="" className="md:w-auto w-16 " />
          <span className="mt-3 font-extrabold lg:text-lg">
            {" "}
            Luxury Hotel & Condotel
          </span>
          <p className=" lg:text-base text-sm md:mt-4 mt-2  text-center">
            This building includes a Luxury Hotel and Condotel , offering
            premium accommodation and services.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
