import { gql } from '@apollo/client';

export const GET_SHOPS = gql`
  query GetShops {
    shop {
      id
      shop_name
      shop_logo
      floor
      category {
        id
        category_name
      }
    }
  }
`;

export const GET_SHOP_BY_ID = gql`
  query GetShopById($id: Int!) {
    shop_by_pk(id: $id) {
      id
      shop_name
      shop_logo
      floor
      category {
        id
        category_name
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GetCategories {
    category {
      id
      category_name
    }
  }
`;
