// src/graphql/queries/bannerQueries.js
import { gql } from '@apollo/client';

// Query to fetch all banners
export const GET_BANNERS = gql`
  query GetBanners {
    banner(order_by: { created_at: asc }) {
      id
      image_name
    }
  }
`;