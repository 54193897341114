import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

function MainLayout() {
  const location = useLocation();
  const isContactUsPage = location.pathname === "/contactus";
  return (
    <div className="flex flex-col min-h-screen">
      {/* Navigation */}
      <Nav />
 
      {/* Main Content Outlet */}
      <main className={`flex-grow ${isContactUsPage ? "bg-secondaryColor" : ""}`}>
        <Outlet />
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default MainLayout;
