// src/graphql/client.js
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: "https://api.citysquareshoppingcentertgi.com/v1/graphql",
});

const authLink = setContext((_, { headers }) => {
  const hasuraAdminSecret = process.env.REACT_APP_HASURA_ADMIN_SECRET;

  return {
    headers: {
      ...headers,
      "x-hasura-admin-secret": hasuraAdminSecret,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
