import React from "react";
import calendar from "../assets/calendar.png";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_NEWS } from "../graphql/queries/newsQueries";
import { GET_NEWS_BY_ID } from "../graphql/queries/newsQueries";
import moment from "moment";

export default function NewsEvent() {
  const { id } = useParams(); // Get the ID from the URL
  const { data: newsData } = useQuery(GET_NEWS);
  const { data: newsById, loading, error } = useQuery(GET_NEWS_BY_ID, {
    variables: { id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const newsItem = newsById.news_by_pk;

  return (
    <div className="lg:pt-28 md:pt-24 pt-16 lg:w-10/12 w-11/12 mx-auto">
      <div className="">
        <Link to={"/"}>Home / </Link>
        <span className="text-secondaryColor font-bold "> News and Events</span>
      </div>
      <div className="mt-4 w-full">
        <img src={newsItem.image_name} alt="" className="w-full"></img>
      </div>
      {/* for calendar logo & date Section */ }
      <div className="pt-6 flex">
        <img src={calendar} alt="" className=" "></img>
        <span className="text-secondaryColor lg:text-lg text-base font-bold ml-4">
        {moment(newsItem.created_at).format("MMM Do YYYY") }
        </span>
      </div>
      {/* for Moonsoon season n text Section */}
      <div className="">
        <div className="lg:text-xl text-lg font-extrabold text-secondaryColor pt-4">
          {newsItem.name}
        </div>
        <p className="lg:text-lg md:text-base text-sm lg:leading-10 md:leading-7 leading-6 pt-4">
          {newsItem.description}
        </p>
      </div>
      {/*  Recent Section */}
      <p className="lg:text-xl text-lg font-extrabold pt-4">Recent Posts</p>

      <div className="grid md:grid-cols-2 grid-cols-1 gap-6 md:my-8 my-4">
          {newsData.news.map((newsItem) => (
              <div key={newsItem.id}>
                <img src={newsItem.image_name} alt="newsimg" />
                <div className="text-secondaryColor flex flex-col space-y-3 mt-4">
                  <p className="flex items-center space-x-4">
                    <Icon
                      icon="formkit:date"
                      className="lg:text-2xl md:text-xl"
                    />
                    <span className="lg:text-base text-sm">{moment(newsItem.created_at).format("MMM Do YYYY") }</span>
                  </p>
                  <p className="font-extrabold">{newsItem.name}</p>
                  <p className="lg:text-base text-sm">
                  {newsItem.description.slice(0, 80)}{newsItem.description.length > 80 && "..."}
                  </p>

                  <Link
                    to={`/news/${newsItem.id}`}
                    className="flex items-center space-x-2 mt-2"
                  >
                    <span className="lg:text-base text-sm font-bold">
                      Read More
                    </span>
                    <Icon
                      icon="pajamas:long-arrow"
                      className="lg:text-xl text-base"
                    />
                  </Link>
                </div>
              </div>
            ))}
        {/* <div>
          <img src={newsimg1} alt="" />
          <div className="text-secondaryColor flex flex-col space-y-3 mt-4">
            <p className="flex items-center space-x-4">
              <Icon icon="formkit:date" className="lg:text-2xl md:text-xl" />
              <span className="lg:text-base text-sm">June 29/2024</span>
            </p>
            <p className="font-extrabold">Monsoon Season Sale</p>
            <p className="lg:text-base text-sm">
              City Square Shopping Center Taunggyi is a bustling center of
              business activity right in the heart of the city. This shopping
              Centre is a...
            </p>

            <Link to={"/news"} className="flex items-center space-x-2 mt-2">
              <span className="lg:text-base text-sm font-bold">Read More</span>
              <Icon
                icon="pajamas:long-arrow"
                className="lg:text-xl text-base"
              />
            </Link>
          </div>
        </div>
        <div>
          <img src={newsimg2} alt="" />
          <div className="text-secondaryColor flex flex-col space-y-3 mt-4">
            <p className="flex items-center space-x-4">
              <Icon icon="formkit:date" className="lg:text-2xl md:text-xl" />
              <span className="lg:text-base text-sm">June 29/2024</span>
            </p>
            <p className="font-extrabold">Monsoon Season Sale</p>
            <p className="lg:text-base text-sm">
              City Square Shopping Center Taunggyi is a bustling center of
              business activity right in the heart of the city. This shopping
              Centre is a...
            </p>
            <Link to={"/news"} className="flex items-center space-x-2 mt-2">
              <span className="lg:text-base text-sm font-bold">Read More</span>
              <Icon
                icon="pajamas:long-arrow"
                className="lg:text-xl text-base"
              />
            </Link>
          </div>
        </div>
        <div>
          <img src={newsimg1} alt="" />
          <div className="text-secondaryColor flex flex-col space-y-3 mt-4">
            <p className="flex items-center space-x-4">
              <Icon icon="formkit:date" className="lg:text-2xl md:text-xl" />
              <span className="lg:text-base text-sm">June 29/2024</span>
            </p>
            <p className="font-extrabold">Monsoon Season Sale</p>
            <p className="lg:text-base text-sm">
              City Square Shopping Center Taunggyi is a bustling center of
              business activity right in the heart of the city. This shopping
              Centre is a...
            </p>
            <Link to={"/news"} className="flex items-center space-x-2 mt-2">
              <span className="lg:text-base text-sm font-bold">Read More</span>
              <Icon
                icon="pajamas:long-arrow"
                className="lg:text-xl text-base"
              />
            </Link>
          </div>
        </div>
        <div>
          <img src={newsimg2} alt="" />
          <div className="text-secondaryColor flex flex-col space-y-3 mt-4">
            <p className="flex items-center space-x-4">
              <Icon icon="formkit:date" className="lg:text-2xl md:text-xl" />
              <span className="lg:text-base text-sm">June 29/2024</span>
            </p>
            <p className="font-extrabold">Monsoon Season Sale</p>
            <p className="lg:text-base text-sm">
              City Square Shopping Center Taunggyi is a bustling center of
              business activity right in the heart of the city. This shopping
              Centre is a...
            </p>
            <Link to={"/news"} className="flex items-center space-x-2 mt-2">
              <span className="lg:text-base text-sm font-bold">Read More</span>
              <Icon
                icon="pajamas:long-arrow"
                className="lg:text-xl text-base"
              />
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  );
}
